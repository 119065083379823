<template>
  <div class="box">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed': ''">
      <img @click="goback"  src="../../../../static/common/logo.png" alt="">
    </div>
    <img class="banner" src="/subject/system/wap/banner.png" alt="" />
    <div class="model_view">
      <img src="/subject/system/wap/model_bj_1.png" class="bj_style" />
      <div class="model_center">
        <img src="/subject/system/wap/model_title_1.png" style="width: 206px" />
        <img
          src="/subject/system/wap/model_1_img.png"
          style="width: 303px; margin-top: 20px"
        />
        <div class="link_button" @click="openLogin">前往用户中心</div>
        <div class="model1_tetx">
          <img src="/subject/system/wap/model1_text.png" />
        </div>
      </div>
    </div>
    <div class="model_view">
      <img src="/subject/system/wap/model_bj_2.png" class="bj_style" />
      <div class="model_center">
        <img src="/subject/system/wap/model_title_2.png" style="width: 210px" />
        <img src="/subject/system/wap/model2_text.png" style="width: 263px; margin-top: 15px;" />
        <img src="/subject/system/wap/model2_table.png" style="width: 283px; margin-top: 15px;" />
        <div style="font-size:15px; color:#e8544f; margin:24px 0 13px;">每升一级，享受更高的点差优惠<br />级数越高，越省钱</div>
        <img src="/subject/system/wap/model_2_down.png" style="width: 167px" @click="openLogin"/>
      </div>
    </div>
    <div class="model_view">
      <img src="/subject/system/wap/h5Back.png" class="bj_style" />
      <div class="model_center">
        <img src="/subject/system/wap/model_title_3.png" style="width: 210px" />
        <img src="/subject/system/wap/model_text_3.png" style="width: 280px; margin:23px 0 5px;" />
        <!-- <img src="/subject/system/wap/model3_table.png" style="width: 270px" /> -->
        <img src="/subject/system/h5.png" style="width: 248px; margin-top: 14px;" />
        <img src="/subject/system/wap/model3_button.png" style="width: 167px; margin-top: 15px;" @click="openZJD"/>
      </div>
    </div>
    <div class="model_view">
      <img src="/subject/system/wap/model_bj_4.png" class="bj_style" />
      <div class="model_center">
        <div class="positionImg">
          <img src="/subject/system/wap/model_title_4.png" style="width: 205px"/>
          <img src="/subject/system/wap/title_img.png" style="width: 111px"/>
        </div>
        <img src="/subject/system/wap/model4_img.png" style="width: 305px"/>
        <div style="font-size:14px; color:#c1a368; margin-top:18px;">
          层级只升不降<br />为您保留层级  近期无交易打算  也不会有降级风险
        </div>
        <div class="model_4_button">
          <img src="/subject/system/wap/model4_button_1.png" @click="openLogin"/>
          <img src="/subject/system/wap/model4_button_2.png" @click="live800"/>
        </div>
      </div>
    </div>
        <contactusVue></contactusVue>
  </div>
</template>
<script>
import ContactusVue from "../../Contactus.vue";
export default {
  name: "systemWap",
  components:{
    ContactusVue
  },
  data() {
    return {
      navBarFixed: false,
    };
  },
  created() {
    // 传给App.vue,不展示tabbar
     this.$emit("controlShow", true);
  },
  methods: {
    // 客服链接
    live800(){
      window.open(
        this.$parent.live800Url
        // 'https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377'
      );
    },
    openLogin(){
      window.open('https://www.rlcvipltd.net/uc/login');
    },
    openZJD() {
      window.open('https://www.rlcvipltd.net/uc/specialEvent/earnBonus');
    },
    goback(){
      window.location.href = window.location.origin
    }
  }
};
</script>
<style lang="less" scoped>
.bj_style {
  width: 100%;
}
.box .tabbar {
    width: 100%;
    background-color: #ffffff;
    img{
      margin: 10px 20px;
    width: 110px;
    }
}
.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #e0b272;
  .banner {
    width: 100%;
  }
  .model_view {
    padding: 0 15px;
    position: relative;
    font-size: 0;
    margin-bottom: 10px;
    .model_center {
      position: absolute;
      width: 100%;
      height: calc(100% - 23px);
      top: 23px;
      left: 0;
      text-align: center;
      font-size: 0;
    }
  }
  .link_button {
    position: absolute;
    width: 121px;
    height: 34px;
    border: 1px solid #e8544f;
    line-height: 34px;
    text-align: center;
    font-size: 15px;
    color: #e8544f;
    border-radius: 34px;
    cursor: pointer;
    right: 66px;
    bottom: 80px;
  }
  .model1_tetx {
    position: absolute;
    width: 100%;
    bottom: 22px;
    left: 0;
    text-align: center;
    > img {
      width: 224px;
    }
  }
  .positionImg {
    position: relative;
    margin-bottom: 44px;
    > img {
      &:nth-child(2) {
        position: absolute;
        right: 43px;
        top: 25px;
      }
    }
  }
  .model_4_button {
    margin-top: 15px;
    > img {
      width: 136px;
      &:nth-child(1) {
        margin-right: 15px;
      }
    }
  }
}
</style>
